import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faHeartCircleMinus } from '@fortawesome/free-solid-svg-icons';

import { useStore } from '../../store/Store';
import { setFavoriteSelected } from '../../store/FeedReducer';

import './style.css';

const FavoriteButton = () => {
  const [{ favoriteSelected }, dispatch] = useStore();

  return (
    <>
      { favoriteSelected && (
      <Button onClick={() => dispatch(setFavoriteSelected(false))}>
        <FontAwesomeIcon icon={faHeartCircleMinus} />
        &nbsp;
        Verwijder Focus
      </Button>
      )}
      { !favoriteSelected && (
      <Button onClick={() => dispatch(setFavoriteSelected(true))}>
        <FontAwesomeIcon icon={faHeart} />
        &nbsp;
        Toevoegen
      </Button>
      )}
    </>
  );
};

export default FavoriteButton;

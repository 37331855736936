import React from 'react';
import {
  Col, Container, Nav, Row,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import Rating from '../Rating';
import DataField from '../data-field/index.ts';

import { brewingMethods } from '../../services/constants.ts';

import './style.css';

interface CoffeeDetailProps {
  coffeeName: string,
  brandName: string,
  brandLink?: string,
  brewMethod: string,
  rating?: number,
  averageRating?: number,
  // ratingCount: number,
  comment?: string,
  roastDate?: Date,
  roastType?: string,
  openDate?: Date,
}

const formatDate = (date: Date) => date.toLocaleDateString();

const CoffeeDetail = (props: CoffeeDetailProps) => {
  const {
    coffeeName,
    brandName,
    brandLink = undefined,
    brewMethod,
    rating = undefined,
    averageRating = undefined,
    comment = undefined,
    roastDate = undefined,
    roastType = undefined,
    openDate = undefined,
  } = props;

  const history = useHistory();

  // const handleClick = () => {
  //   if (onClick !== undefined) {
  //     onClick();
  //   }
  // };

  const navigateToBrand = () => {
    history.push(`${brandLink}`);
  };

  return (
    <Container className="coffeeDetail">
      <Row>
        <Col>
          <div>
            <div className="label">Koffie</div>
            <div className="value">{coffeeName}</div>
          </div>
        </Col>
        <Col>
          <div>
            <div className="label">Merk</div>
            { brandName && brandLink
              && <div className="value"><Nav.Link onClick={navigateToBrand}>{brandName}</Nav.Link></div>}
            { brandName && !brandLink
              && <div className="value">{brandName}</div>}
            { !brandName
              && <div className="value">(onbekend)</div>}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div>
            <div className="label">Zetmethode</div>
            <div className="value">{brewingMethods[brewMethod] || '-'}</div>
          </div>
        </Col>
        <Col>
          <div>
            <div className="label">Branding</div>
            <div className="value">{roastType || '(onbekend)'}</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <DataField label="Gebrand">
            {roastDate
              ? formatDate(roastDate)
              : '(geen branddatum)'}
          </DataField>
        </Col>
        <Col>
          <DataField label="Geopend Op">
            {openDate
              ? formatDate(openDate)
              : '(nog niet geopend)'}
          </DataField>
        </Col>
      </Row>
      <Row>
        <Col>
          <div>
            <div className="label">Jouw Score</div>
            <div className="value">
              <Rating
                rating={rating}
                readOnly
              />
              <span className="rating small">{rating}</span>
            </div>
          </div>
        </Col>
        <Col>
          <div>
            <div className="label">Gemiddeld</div>
            <div className="value">
              <Rating
                rating={averageRating}
                readOnly
              />
              <span className="rating small">{averageRating}</span>
            </div>
          </div>
        </Col>
      </Row>
      { comment && (
      <Row>
        <Col>
          <div>
            <div className="label">Ervaring</div>
            <div className="value">
              <div className="comment">
                {comment}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      )}
    </Container>
  );
};

export default CoffeeDetail;

import React from 'react';
import {
  Card,
} from 'react-bootstrap';

import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.css';

interface FocusCardProps {
  coffeeName: string,
  coffeeBrand?: string,
  // roastDate?: Date,
  // imageUrl?: string,
}

const FocusCard = (props: FocusCardProps) => {
  const { coffeeName, coffeeBrand } = props;
  return (
    <Card className="focusCard">
      <Card.Body>
        <div className="blabla">
          <div className="metaInfo">
            { coffeeBrand && <div className="coffeeBrand">{coffeeBrand}</div> }
            <div className="coffeeName">{coffeeName}</div>
          </div>
          <div className="icon">
            <FontAwesomeIcon icon={faHeart} size="2x" />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default FocusCard;

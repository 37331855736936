export const ADD_COFFEE = 'APP/FEED/ADD_COFFEE';
export const SET_FEED = 'APP/FEED/SET_FEED';
export const SET_COFFEE_DETAIL = 'APP/COFFEE/DETAIL';
export const SET_FAVORITE_SELECTED = 'APP/FAVORITE';

export const initialState = {
  feed: [],
  coffeeDetail: undefined,
  favoriteSelected: false,
};

export const addCoffee = (item) => ({
  type: ADD_COFFEE,
  item,
});

export const setFeed = (feed) => ({
  type: SET_FEED,
  feed,
});

export const setCoffeeDetail = (detail) => ({
  type: SET_COFFEE_DETAIL,
  detail,
});

export const setFavoriteSelected = (selected) => ({
  type: SET_FAVORITE_SELECTED,
  selected,
});

export const feedReducer = (state, action) => {
  const {
    type, item, feed, detail, selected,
  } = action;
  switch (type) {
    case ADD_COFFEE:
      console.log(`add coffee to feed: ${JSON.stringify(item)}`);
      return {
        ...state,
        feed: [item].concat(state.feed),
      };
    case SET_FEED:
      console.log('set feed!');
      return {
        ...state,
        feed,
      };
    case SET_COFFEE_DETAIL:
      console.log(`set coffee detail: detail=${JSON.stringify(detail)}`);
      return {
        ...state,
        coffeeDetail: detail,
      };
    case SET_FAVORITE_SELECTED:
      console.log(`set favorite selected to: ${selected}`);
      return {
        ...state,
        favoriteSelected: selected,
      };
    default:
      console.log(`unknown type: ${type}`);
      return state;
  }
};

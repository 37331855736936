import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Modal,
  Button,
  Spinner,
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import CoffeeDetail from '../../components/coffee-detail/index.ts';
import FavoriteButton from '../../components/favorite-button/index.ts';

import './style.css';

interface FocusPageProps {
  coffeeName: string,
  coffeeBrand?: string,
}

const FocusPage = (props: FocusPageProps) => {
  const [isLoading] = useState<boolean>(false);

  const history = useHistory();

  const {
    coffeeName = 'Lebkov',
    coffeeBrand = 'Organic Espresso Beans',
  } = props;

  // TODO
  // * PRO-setting

  return (
    <Modal show fullscreen className="focusPage">
      <Modal.Header>
        <Modal.Title>
          <Button variant="outline-primary" aria-label="Back" onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <div>Jouw koffie</div>
        </Modal.Title>
      </Modal.Header>
      { isLoading && (
        <Modal.Body>
          <Spinner animation="border" size="sm" />
        </Modal.Body>
      )}
      { !isLoading && (
        <Modal.Body>
          <CoffeeDetail
            coffeeName={coffeeName}
            brandName={coffeeBrand}
            brewMethod="espresso"
          />
          <div>
            <hr />
            Hier moeten de PRO-settings komen!
            <hr />
          </div>
          <div className="buttonContainer">
            <FavoriteButton />
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default FocusPage;
